<template>
  <div class="workout">
    <!--DISPLAY MODE-->
    <div class="workout-display" v-if="currentMode=='display'">
      <!-- week name -->
      <vs-row vs-type="flex" vs-justify="space-between" vs-w="12">
        <vs-col
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="12"
          :class="{'w-l-header':true, 'today':active}"
        >{{weekName}}</vs-col>
      </vs-row>

      <!-- WEEK DAYS -->
      <vs-row vs-type="flex" vs-justify="space-between" vs-w="12">
        <vs-col vs-w="12" vs-type="flex" vs-justify="left" vs-align="flex-start" class="w-l-item">
          <!-- month day and actions -->
          <div class="w-l-i-head">
            <span :class="{'day':true, 'day-active': isCurrentMonth, 'today':active}">{{dayName}}</span>
            <span
              :class="{'title':true, 'finished':(workout && (workout.state=='completed' || workout.rest_day )), 'missed':(workout && workout.state=='missed')}"
            >{{workout?(workout.rest_day?'休息日':workout.title):''}}</span>

            <span class="actions">
              <vs-dropdown>
                <a class="flex items-center" href="#">
                  <i class="material-icons">menu</i>
                </a>
                <vs-dropdown-menu>
                  <!-- 没有计划 -->
                  <vs-dropdown-item @click="addWrokout(false)" v-if="!workout">
                    <feather-icon icon="PlusCircleIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span class="ml-2">添加训练计划</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="addWrokout(true)" v-if="!workout">
                    <feather-icon icon="CoffeeIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span class="ml-2">设置为休息日</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="pasteWorkout({workout_src:workoutCopy, client_id:client.id, day:day})" :disabled="!workoutCopy" v-if="!workout">
                    <feather-icon icon="CopyIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span class="ml-2">粘贴计划</span>
                  </vs-dropdown-item>
                  <!-- 有计划 -->
                  <vs-dropdown-item @click="editWorkout(false)" v-if="workout && !workout.rest_day && workout.state=='pending'">
                    <feather-icon icon="EditIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span class="ml-2">编辑训练计划</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="deleteWorkout()" v-if="workout && workout.rest_day">
                    <feather-icon icon="EditIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span class="ml-2">取消休息日</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="editWorkout()" v-if="workout && workout.state!='pending'">
                    <feather-icon icon="EyeIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span class="ml-2">查看训练计划</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="setWorkoutCopy(workout)" v-if="workout">
                    <feather-icon icon="CopyIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span class="ml-2">复制训练计划</span>
                  </vs-dropdown-item>
                  <vs-dropdown-item @click="deleteWorkout(workout)" v-if="workout && !workout.rest_day">
                    <feather-icon icon="CopyIcon" svgClasses="w-4 h-4"></feather-icon>
                    <span class="ml-2">删除训练计划</span>
                  </vs-dropdown-item>
                </vs-dropdown-menu>
              </vs-dropdown>
            </span>
          </div>
        </vs-col>
      </vs-row>

      <!-- workout-warmup -->
      <vs-row
        v-if="workout && workout.warmup"
        class="workout-warmup"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-col class="workout-warmup-content" vs-w="12" v-html="htmlFormat(workout.warmup)"></vs-col>
      </vs-row>

      <!-- workout-items -->
      <vs-row
        v-for="(workoutItem, idxWKItm) in workoutItems"
        :key="idxWKItm"
        class="w-i-d-workout-item"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-col vs-type="flex" vs-justify="flex-start" class="workout-item-icon">
          <feather-icon
            v-if="workoutItem.state == 'completed' || workoutItem.state == 'complated'"
            icon="CheckIcon"
            svgClasses="text-success h-4 w-4"
          />
          <feather-icon
            v-if="workoutItem.state == 'missed'"
            icon="XIcon"
            svgClasses="text-danger h-4 w-4"
          />
          <feather-icon
            v-if="workoutItem.state == 'pending'"
            icon="ClockIcon"
            svgClasses="text-gray-400 h-4 w-4"
          />
        </vs-col>
        <vs-col class="workout-item-title">
          <div class="workout-item-name">{{workoutItem.name}}</div>
          <div class="workout-item-info" v-html="splitWorkoutItemInfo(workoutItem.info)"></div>
          <div class="workout-item-result" v-if="workoutItem.result" v-html="workoutItem.result"></div>
        </vs-col>
      </vs-row>

      <!-- workout-cooldown -->
      <vs-row
        v-if="workout && workout.cooldown"
        class="workout-warmup"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="flex-start"
        vs-w="12"
      >
        <vs-col class="workout-warmup-content" vs-w="12" v-html="htmlFormat(workout.cooldown)"></vs-col>
      </vs-row>

      <!--workout-comments-->
      <!-- <vs-row
        v-if="workout"
        class="workout-comments"
        vs-type="flex"
        vs-justify="flex-start"
        vs-w="12"
      >
        <vs-col vs-type="flex" vs-justify="flex-start" vs-w="12">
          <div class="workout-comments-content">
            <span v-for="(comment, cIdx) in getComments(workout.comment_ids)" :key="cIdx">
              {{comment.body}}
              <br />
            </span>
          </div>
        </vs-col>
      </vs-row> -->
    </div>

    <!--EDIT MODE-->
    <!-- <div class="workout-edit" v-if="currentMode=='edit'">
    </div>-->
    <vs-popup fullscreen title :active.sync="showEditBox">
      <WorkoutEdit
        v-if="workout"
        :showPopup="showEditBox"
        :workout_org="workout"
        @hide="hideEditPopup"
        @save="updateWorkup"
        @reset="resetWorkout"
        @reload="$emit('reload')"
      />
    </vs-popup>
  </div>
</template>

<script>
//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

import { mapState, mapActions, mapMutations} from "vuex";

import WorkoutEdit from "./WorkoutEdit.vue";

export default {
  mixins: [baseFun],
  props: ["day", "activeDate", "client", "user", "workout_org", "event", "active", "activeDue"],

  data() {
    return {
      myMode: this.mode,
      showEditBox: false,
      workout: null
    };
  },

  watch: {
    workout_org(newVal) {
      // this.workout = this.workout_org
      this.workout = JSON.parse(JSON.stringify(newVal));
    },

    showEditBox(newVal){
      if (!newVal){
        if (this.workout && !this.workout.id){
          this.workout = null;
        }
      }
    }
  },

  created() {
    this.workout = JSON.parse(JSON.stringify(this.workout_org));
    this.showEditBox = this.workout? (this.activeDue == this.workout.due):false;
  },

  computed: {
    currentMode: {
      get: function() {
        return this.myMode || "display";
      },
      set: function(val) {
        this.myMode = val;
      }
    },

    isCurrentMonth() {
      return (
        this.activeDate.getMonth() == this.day.getMonth() &&
        this.activeDate.getFullYear() == this.day.getFullYear()
      );
    },

    weekName() {
      let name = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六"
      ];
      return name[this.day.getDay()];
    },

    dayName() {
      return this.day.getMonth() + 1 + "/" + this.day.getDate();
    },

    workoutClone() {
      return JSON.parse(JSON.stringify(this.workout));
    },

    // // get workout_item list for a workout
    // workoutItems() {
    //   if (!this.workout) return null;

    //   let ids = this.workout.workout_item_ids;
    //   if (!ids) ids = [];

    //   // console.log(ids);
    //   // console.log(this.workout_items);
    //   let workoutItems = this.workout_items.filter(item =>
    //     ids.includes(item.id)
    //   );

    //   return workoutItems.sort((a, b) => a.position - b.position);
    // },

    // get workout_item list for a workout
    workoutItems() {
      if (!this.workout || !this.workout.workout_items) return [];

      let workoutItems = JSON.parse(JSON.stringify(this.workout.workout_items));
      return workoutItems.sort((a, b) => a.position - b.position);
    },

    worktoutSateClass() {
      let colorName = {
        completed: "text-success",
        missed: "text-danger",
        pending: "text-black"
      };

      return this.workout ? colorName[this.workout.state] : "";
    },

    ...mapState([
      "activeTrainer",
      "activeUser",
      "activeOrganization",
      "organizations",
      "trainers",
      "clients",
      "users",
      "groups",
      "group_assignments",
      "workouts",
      "workout_items",
      "comments",
      "workoutCopy"
    ])
  },

  // created() {
  //   // this.workout = this.getWorkout();
  // },

  // watch: {
  //   day: function() {
  //     // this.workout = this.getWorkout();
  //   }
  // },

  methods: {
    // getWorkout() {
    //   let day = this.day;
    //   let client_id = this.client.id;
    //   if (day.getDate()==2 && day.getMonth()==9) {
    //     console.log(day)
    //     this.workouts.forEach(item=>console.log(item.due.substr(0,10), item.position, item.title, item.state, item))
    //   }
    //   let dueWorkouts = this.workouts.filter(wk => {
    //     let due = new Date(wk.due);
    //     return (
    //       wk.client_id == client_id &&
    //       due.getDate() == day.getDate() &&
    //       due.getMonth() == day.getMonth() &&
    //       due.getFullYear() == day.getFullYear()
    //     );
    //   });
    //   return dueWorkouts && dueWorkouts.length ? dueWorkouts[0] : null;
    // },

    splitWorkoutItemInfo(info) {
      return info.split("\n").join("<br/>");
    },

    htmlFormat(val) {
      return val ? val.replace(/\n/g, "<br/>") : "";
    },

    showEditPopup() {
      this.showEditBox = true;
    },

    hideEditPopup() {
      this.showEditBox = false;
    },

    updateWorkup() {
      // this[val.old] = this[val.new];
      this.$emit("reload");
    },

    popupEditTitle() {
      // let user = this.user();
      // return user.first_name + " " + user.last_name + " / " + this.workout.due;
      return this.user.name;
    },

    //增加新的workout
    addWrokout(isRest) {
      // console.log("add work out " + dateVal);
      if (this.workout) return;
      this.workout = this.getNewWorkout(this.client.id, this.day, isRest);
      if (isRest){
        this.$store.dispatch("createWorkout", { workout: this.workout });
      } else {
        this.showEditBox = true;
      }
    },

    resetWorkout(){
      this.workout = null;
    },

    editWorkout() {
      this.showEditBox = true;
    },

    deleteWorkout(){
      this.$vs.dialog({
        type: 'confirm',
        color:'danger',
        title: `警告`,
        text: ' 删除动作不可恢复，是否确认删除此workout？ ',
        "accept-text": '确定',
        "cancel-text": '取消',
        accept:this.deleteWorkoutProcess
      })
    },

    deleteWorkoutProcess() {
      if (this.workout){
        if (this.workout.id) {
          this.$store.dispatch("deleteWorkout", this.workout.id);
          this.$store.commit("remove_array_item", {name:"workouts", data:[this.workout.id]});
        } else {
          this.workout = null;
        }
      }
    },

    ...mapMutations({
      setWorkoutCopy: 'set_workout_copy'
    }),

    ...mapActions({
      pasteWorkout: 'pasteWorkout'
    })
  },

  components: {
    WorkoutEdit
  }
};
</script>

<style scoped>
.workout {
  width: 100%;
  min-height: 240px;
}

.day-active {
  color: #000;
}
.day-active.today{
  color:rgb(38, 140, 242);
  font-weight: bold;
}
.w-l-item {
  padding: 0;
}
.w-l-header {
  background-color: #dee1e4;
  color: #a4b5c4;
  border: 1px solid white;
  padding: 5px 0;
}
.w-l-header.today{
  color:rgb(38, 140, 242);
  font-weight: bold;
}
.w-l-i-head {
  width: 100%;
  background-color: rgba(222, 225, 228, 0.5);
  color: #c2c8cd;
  border-bottom: 1px solid #dee1e4;
  /* padding: 0 15px; */
}
.w-l-i-head span {
  display: inline-block;
  padding-left: 5px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.w-l-i-head span.day {
  width: 45px;
  text-align: left;
}
.w-l-i-head span.title {
  width: calc(100% - 115px);
  text-align: center;
  font-weight: bold;
  color: #000;
}
.w-l-i-head span.title.finished {
  color: green;
}
.w-l-i-head span.title.missed {
  color: red;
}

.w-l-i-head span.actions {
  width: 70px;
  text-align: right;
  line-height: 13px;
  padding-right: 15px;
}
.w-l-i-head span.actions .action {
  top: 2px;
}
.w-l-i-head span.actions .action:hover {
  color: rgb(115, 103, 240);
}
.w-i-d-workout-item {
  padding-top: 5px;
}
.workout-actions {
  color: #a4b5c4;
}

.workout-warmup {
  font-size: 12px;
  color: #a4b5c4;
  border-bottom: 1px solid #dee1e4;
}
.workout-warmup-content {
  /* max-height: 40px; */
  overflow: hidden;
}

.workout-item-icon {
  width: 20px !important;
  padding: 6px 4px;
}

.workout-item-title {
  width: calc(100% - 20px) !important;
  padding: 2px;
}
.workout-item-name {
  font-weight: bold;
  font-size: 90%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}
.workout-item-info {
  font-size: 80%;
}
.workout-item-info-pre {
  overflow: hidden;
  text-overflow: ellipsis;
}
.workout-item-result {
  color: #a4b5c4;
  font-size: 80%;
}

.workout-comments {
  margin-top: 5px;
  /* border-top: 1px solid #dee1e4; */
  font-size: 80%;
  color: #a4b5c4;
}

span.ml-2, .vs-dropdown--item{
  white-space: nowrap;
}

.workout-comments-content{
  overflow: hidden;
}

.workout-comments-content > span{
  text-overflow: ellipsis;
  display: inline-block;
  width: 100%;
  overflow: hidden;
}

</style>
