<template>
  <div id="client-workouts">
    <!--HEADER-->
    <div class="vx-row workout-header">
      <!-- Current Month -->
      <div class="vx-col w-6/12 flex justify-left order-last">
        <div class="flex items-center">
          <div class="mx-3 flex items-center">
            <span v-if="user">{{user.name}}({{user.mobile}})</span>
          </div>

          <feather-icon
            icon="ChevronLeftIcon"
            @click="updateMonth(-1)"
            class="cursor-pointer bg-primary text-white rounded-full"
          />
          <span class="mx-3 text-xl font-medium whitespace-no-wrap">{{ currentMonth }}</span>
          <feather-icon
            icon="ChevronRightIcon"
            @click="updateMonth(1)"
            class="cursor-pointer bg-primary text-white rounded-full"
          />

          <!-- go today -->
          <div class="btn-group mx-3 flex items-center goto-buttons">
            <vs-button type="line" @click="updateToday(new Date())">显示今日</vs-button>
            <vs-button type="line" @click="activeTodayPrompt=true" icon="menu">选择日期</vs-button>
            <!-- <input v-model="deleteID" placeholder="workout id to delete" />
            <vs-button type="line" @click="deleteWorkout" icon="menu">delete</vs-button>-->
          </div>

          <!-- go select day -->
          <vs-popup title="选择日期" :active.sync="activeTodayPrompt" class="dt-popup">
            <datepicker
              @selected="updateToday"
              :inline="true"
              :language="lan.zh"
              :value="activeTodayVal"
            ></datepicker>
          </vs-popup>
        </div>
      </div>

      <div class="vx-col w-6/12 flex justify-end order-last">
        <vs-button @click="loadWorkouts()">刷新</vs-button>
      </div>
    </div>

    <!-- WORKOUTS -->
    <vx-card class="workout-list">
      <vs-row
        v-for="(week, indexWeek) in dateRange"
        :key="indexWeek"
        vs-type="flex"
        vs-justify="flex-start"
        vs-align="stretch"
        vs-w="12"
      >
        <vs-col
          v-for="(day, indexDay) in week"
          :key="indexDay"
          vs-type="flex"
          vs-justify="flex-start"
          vs-align="flex-start"
          vs-w="1.71"
          :class="{'w-l-i-item':true, 'today':isActiveDate(day)}"
        >
          <CHWorkoutDay
            :day="day"
            :activeDate="activeDate"
            :client="client"
            :user="user"
            :active="isActiveDate(day)"
            :workout_org="getClientWorkout(client, day)"
            :activeDue="defaultDue"
            @reload="loadWorkouts(true)"
            mode="display"
          />
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import DTUtil from "@/assets/utils/datetime.js";

import Datepicker from "vuejs-datepicker";
import * as lang from "vuejs-datepicker/src/locale";

import CHWorkoutDay from "@/views/components/coach/workout/Workout.vue";

// //sample data
// import * as sample from "@/store/samples/coach/workouts/workouts.js"
import { mapState } from "vuex";

//common functions
import baseFun from "@/views/components/coach/baseFunctions.js";

export default {
  mixins: [baseFun],
  data() {
    return {
      activeDate: new Date(),
      activeTodayPrompt: false,
      lan: lang,
      client: null,
      user: null,
      defaultDue: null,
      // deleteID: 0
    };
  },

  created() {
    //console.log(this.$route)
    this.loadWorkouts();
    this.loadBaseInfo();
  },

  // watch:{
  //   '$route' (to, from) {
  //     console.log(to, from);
  //   }
  // },

  // beforeRouteUpdate(to, from, next){
  //   let id = to.params.id;
  //   console.log(id);
  //   next();
  // },

  computed: {

    //当前月份
    currentMonth() {
      let sMon = this.activeDate.getMonth() + 1;
      sMon = ("0" + sMon).substr(-2);
      return this.activeDate.getFullYear() + "年" + sMon + "月";
    },

    //选中的日期
    activeTodayVal: {
      get() {
        return this.activeDate;
      },
      set(val) {
        this.activeDate = val;

        //日期范围变动了，需要重新装载workouts
        this.loadWorkouts();
      }
    },

    //显示的日期范围（上个月和下个月，凑足周）
    dateRange() {
      let range = DTUtil.getMonthWeekRange(this.activeDate);
      let weeks = [];
      let week = [];
      let currentDay = range.firstDay;
      do {
        week.push(currentDay);
        currentDay = new Date(currentDay.getTime() + 1000 * 3600 * 24);
        if (week.length == 7) {
          weeks.push(week);
          week = [];
        }
      } while (currentDay.getTime() <= range.lastDay.getTime());
      return weeks;
    },

    ...mapState([
      "activeTrainer",
      "organizations",
      "trainers",
      "clients",
      "users",
      "groups",
      "group_assignments",
      "workouts",
      "workout_items",
      "activeTrainer",
      "activeUser",
      "activeOrganization",
      "comments"
    ])
  },

  methods: {
    isActiveDate(date){
      return date.getFullYear() == this.activeDate.getFullYear() &&
      date.getMonth() == this.activeDate.getMonth() &&
      date.getDate() == this.activeDate.getDate()
    },

    loadBaseInfo(){
      this.$store.dispatch("getPrograms");
      this.$store.dispatch("getExercises");
      this.$store.dispatch("getWarmups");
      this.$store.dispatch("getCooldowns");
    },

    loadWorkouts(delay) {
      let self = this;
      // debugger
      this.client = this.getClientBySlug(this.$route.params.slug);
      let id = this.$route.params.id;
      if (id) {
        let workout = this.getWorkout(id);
        if (workout) {
          this.activeDate = new Date(workout.due);
          this.defaultDue = workout.due;
        }
      }
      if (!this.client) return;

      this.user = this.getUser(this.client.user_id);
      let range = DTUtil.getMonthWeekRange(this.activeDate);

      let client = this.client;
      // this.$store.dispatch("getClientWorkout", { client, range });
      setTimeout(
        () => self.$store.dispatch("getClientWorkout", { client, range }),
        delay ? 2000 : 0
      );
    },

    //上一个月，下一个月
    updateMonth(val) {
      let newDate = new Date(this.activeDate.toString());
      newDate.setMonth(newDate.getMonth() + val);
      this.activeDate = newDate;
      this.loadWorkouts();
    },

    //设置当前活动日期
    updateToday(val) {
      this.activeTodayVal = val;
      this.activeTodayPrompt = false;
    },

    //判断某天是否是当前月
    isCurrentMonth(day) {
      return this.activeDate.getMonth() == day.getMonth();
    }

    // deleteWorkout() {
    //   if (this.deleteID > 0)
    //     this.$store.dispatch("deleteWorkout", { id: this.deleteID });
    // }
  },

  components: {
    Datepicker,
    CHWorkoutDay
  }
};
//https://github.com/charliekassel/vuejs-datepicker?ref=madewithvuejs.com
</script>

<style>
.goto-buttons {
  height: 38px;
}

.dt-popup .vs-popup {
  width: auto !important;
}
</style>
<style scoped>

/* day workout */
.w-l-i-item {
  min-height: 150px;
  border-left: 1px solid #dee1e4;
  border-right: 1px solid #dee1e4;
  border-bottom: 1px solid #dee1e4;
  padding: 0px;
}
.w-l-i-item.today{
  border:1px solid rgb(115, 103, 240);
}
</style>
